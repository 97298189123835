import React from "react";
import { Container, Row, Col, Button, Table, Spinner } from "react-bootstrap";
import axios from "axios";
import { BsBoxArrowInRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BASE_URL } from "../config/index";
const api = axios.create({
  baseURL: `${BASE_URL}/campaign-transaction`,
});
const CampaignUsePage = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [campaigns, setCampaign] = React.useState([]);
  const cancelToken = React.useRef(null);
  const getData = async () => {
    try {
      setLoading(true);
      const urlPath = `/`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
      });
      setCampaign(resp.data.campaigns);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <div>
      <h1 className="text-center mt-3">Welcome</h1>
      <h1 className="text-center mt-3">Reckitt Benckiser Campaign</h1>
      <Container className="mt-3">
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Limit</th>
                  <th>Use</th>
                  <th>Create By</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.limit}</td>
                      <td>{item.campaignsTransactionsCount}</td>
                      <td>{item.user.name}</td>
                      <td>{item.createdAt_format}</td>
                      <td>
                        <Link to={`campaign-coupon/${item.id}`}>
                          <Button variant="outline-success">
                            Use Campaign <BsBoxArrowInRight />
                          </Button>{" "}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CampaignUsePage;
