import React from "react";
import { Container, Row, Col, Spinner, Button, Form } from "react-bootstrap";
import axios from "axios";
import { BsFillPlusSquareFill } from "react-icons/bs";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../config/index";
const schema = yup.object().shape({
  campaignName: yup.string().required("name not empty"),
  campaignLimit: yup
    .number()
    .positive("จำนวนเท่านั้น")
    .min(1, "มากกว่า 1")
    .required("name not empty"),
});
const api = axios.create({
  baseURL: `${BASE_URL}/`,
});
const CampaignEditPage = () => {
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = React.useState(false);
  const [departments, setDepartment] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const cancelToken = React.useRef(null);
  const profileValue = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      await api.put(
        `/campaign/${id}`,
        {
          name: data.campaignName,
          limit: data.campaignLimit,
          departments: data.departments,
        },
        {
          headers: {
            Authorization: "Bearer " + profileValue.access_token,
          },
        }
      );
      MySwal.fire({
        icon: "success",
        title: "Update Campaign Success",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        history.replace("/campaign");
      });
    } catch (error) {
      // addToast(error.response.data.error.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      //   autoDismissTimeout: 3000,
      // });
    }
  };
  const getData = async () => {
    try {
      if (!profileValue) {
        history.replace("/login");
      }
      setLoading(true);
      const urlPath = `/campaign/${id}`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      setDepartment(resp.data.departments);
      setCampaign(resp.data.data);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/login");
      }
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const clickAll = (e) => {
    let count = 0;

    const checkboxes = document.getElementsByName("departments");
    const AllElement = document.getElementById("All");
    if (e.id === "All") {
      for (const iterator of checkboxes) {
        iterator.checked = e.checked;
      }
    } else {
      for (const iterator of checkboxes) {
        if (iterator.id !== "All") {
          if (!iterator.checked) {
            AllElement.checked = false;
          } else {
            count++;
          }
        }
      }
    }
    if (checkboxes.length - 1 === count) {
      AllElement.checked = true;
    }
  };

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col>
            <Button variant="dark" onClick={() => history.push("/campaign/")}>
              Back to Campaign
            </Button>{" "}
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Col} md="10" controlId="formGridAddress1">
              <Form.Label>Campaign Name</Form.Label>
              <Form.Control
                placeholder="Campaign Name"
                type="text"
                name="campaignName"
                ref={register}
                readOnly
                defaultValue={campaign.name}
                className={`form-control ${
                  errors.campaignName ? "is-invalid" : ""
                }`}
              />
              {errors.campaignName && (
                <Form.Control.Feedback type="invalid">
                  {errors.campaignName.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="10" controlId="Use">
              <Form.Label>Limit</Form.Label>
              <Form.Control
                placeholder="Campaign Limit"
                type="number"
                name="campaignLimit"
                ref={register}
                defaultValue={campaign.limit}
                className={`form-control ${
                  errors.campaignLimit ? "is-invalid" : ""
                }`}
              />
              {errors.campaignLimit && (
                <Form.Control.Feedback type="invalid">
                  {errors.campaignLimit.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row>
              <Col md="12">
                <p>Department</p>
              </Col>

              {departments && (
                <>
                  {departments.map((department, index) => {
                    return (
                      <Col>
                        <Form.Check
                          custom
                          key={`${department}`}
                          type="checkbox"
                          id={`${department}`}
                          ref={register}
                          label={`${department}`}
                          name="departments"
                          value={department}
                          defaultChecked={campaign.departments.includes(
                            department
                          )}
                          onChange={(e) => {
                            clickAll(e.target);
                          }}
                        />
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
            <Button variant="primary" className="mt-3" type="submit">
              <BsFillPlusSquareFill className="mr-2" />
              Save
            </Button>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default CampaignEditPage;
