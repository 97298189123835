import React from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { useParams, useHistory } from "react-router-dom";
import { BASE_URL } from "../config";
import { httpClient } from "../config/https";
const api = axios.create({
  baseURL: `${BASE_URL}/campaign-transaction`,
});
const CampaignCoupon = () => {
  const { id } = useParams();
  const { addToast } = useToasts();
  const checkAs = /^[0-9a-zA-Z_]+$/;
  const [message, setMessage] = React.useState(null);
  const [employee, setEmployee] = React.useState(null);
  const [campaign, setCampaign] = React.useState({});
  const [send, setSend] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(null);
  const cancelToken = React.useRef(null);
  const history = useHistory();
  const profileValue = JSON.parse(localStorage.getItem("token"));
  const [barcodeInputValue, updateBarcodeInputValue] = React.useState("");
  const checkKeyBoard = (value) => {
    return new Promise((resolve, reject) => {
      if (value.match(checkAs) === null) {
        reject(false);
      }
      resolve(true);
    });
  };
  const sendCode = async (value) => {
    try {
      await checkKeyBoard(value);
      const resp = await api.post(
        "/",
        { employeeId: value, campaignId: id },
        {
          headers: {
            Authorization: "Bearer " + profileValue.access_token,
          },
        }
      );
      const {
        message: messageResponse,
        count: countResponse,
        detail,
      } = resp.data;
      addToast(messageResponse, { appearance: "success" });
      setCount(countResponse);
      setMessage(messageResponse);
      setEmployee(detail.employeeId);
      updateBarcodeInputValue("");
    } catch (err) {
      if (err) {
        // if (err.response?.status === 401) {
        //   history.replace("/login");
        // }
        addToast(err.response.data.error.message, { appearance: "error" });
        setMessage(err.response.data.error.message);
      } else {
        addToast("กรุณาเปลี่ยนภาษา", { appearance: "error" });
        setMessage("กรุณาเปลี่ยนภาษา");
      }
      setEmployee("");
    } finally {
      setSend(false);
      barcodeAutoFocus();
      updateBarcodeInputValue("");
    }
  };
  const onKeyPressBarcode = (event) => {
    try {
      if (event.keyCode === 13) {
        setSend(true);
        setEmployee("");
        setMessage("");
        updateBarcodeInputValue();
        sendCode(event.target.value);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const onChangeBarcode = async (event) => {
    try {
      updateBarcodeInputValue(event.target.value);
    } catch (err) {
      console.log(err.message);
    }
  };
  const barcodeAutoFocus = () => {
    try {
      document.getElementById("SearchbyScanning").focus();
    } catch (err) {
      console.log(err.message);
    }
  };
  const getData = async () => {
    try {
      if (!profileValue) {
        history.replace("/login");
      }
      setLoading(true);
      const urlPath = `${BASE_URL}/campaign-transaction/${id}`;
      const resp = await httpClient.get(urlPath, {
        cancelToken: cancelToken.current.token,
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      setCampaign(resp.data.campaign);
      setCount(resp.data.length);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/");
      }
      setMessage(err.message);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  return (
    <>
      <Container className="p-3 pb-md-4 mx-auto mt-5 text-center">
        <Row>
          <Col>
            <Container>
              {campaign && (
                <>
                  <Row className="gx-5">
                    <Col>
                      <h1 className="display-4">Name</h1>
                    </Col>
                    <Col>
                      <h1 className="display-4">{campaign.name}</h1>
                    </Col>
                  </Row>
                  <Row className="gx-5">
                    <Col>
                      <h1 className="display-4">โควต้าวันนี้</h1>
                    </Col>
                    <Col>
                      <h1 className="display-4">{campaign.limit}</h1>
                    </Col>
                  </Row>
                  <Row className="gx-5">
                    <Col>
                      <h1 className="display-4">จำนวนการใช้งาน</h1>
                    </Col>
                    <Col>
                      <h1 className="display-4">{count}</h1>
                    </Col>
                  </Row>
                  <Row className="gx-5">
                    <Col>
                      <h1 className="display-5">สถานะ</h1>
                    </Col>
                    <Col>
                      {message && (
                        <>
                          <h1 className="display-5">{message}</h1>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row className="gx-5">
                    <Col>
                      <h1 className="display-5">รหัสพนักงาน : </h1>
                    </Col>
                    <Col>
                      {employee && (
                        <>
                          <h1 className="display-5">{employee}</h1>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>เฉพาะ</p>
                    </Col>
                    <Col>
                      {campaign.departments && (
                        <>
                          <p>{campaign.departmentsString}</p>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Container>
            <input
              autoFocus={true}
              disabled={send}
              placeholder="Start Scanning"
              value={barcodeInputValue}
              onChange={onChangeBarcode}
              id="SearchbyScanning"
              className="form-control"
              onKeyDown={onKeyPressBarcode}
              onBlur={barcodeAutoFocus}
            />
            <br />
            <Button
              variant="danger"
              className="mt-2"
              onClick={() => {
                updateBarcodeInputValue("");
              }}
            >
              Clear Data
            </Button>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <Button variant="dark" onClick={() => history.push("/campaign/")}>
              Back to Campaign
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CampaignCoupon;
