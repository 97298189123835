import axios from "axios";
axios.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    if (err.response?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(err);
  }
);
export const httpClient = axios;
