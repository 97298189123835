import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import PrivateRoute from "./guard/auth";
import { Provider } from "react-redux";
import NavBar from "./components/NavBar";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import configureStore from "./redux/configureStore";
import Coupon from "./pages/Coupon";
import DateSetting from "./pages/DateSetting";
import Transaction from "./pages/Transaction";
import ChartCount from "./pages/ChartCount";
import CampaignUsePage from "./pages/CampaignUsePage";
import CampaignCoupon from "./pages/CampaignCoupon";
import TransactionCampaign from "./pages/TransactionCampaign";
import DepartmentPage from "./pages/DepartmentPage";
import CampaignIndexPage from "./pages/campaign/CampaignIndexPage";
import CampaignCreatePage from "./pages/campaign/CampaignCreatePage";
import CampaignEditPage from "./pages/campaign/CampaignEditPage";
import EmployeePage from "./pages/EmployeePage";
import UploadExcel from "./pages/UploadExcel";
import DiscountTransaction from "./pages/DiscountTransaction";
import BrandPage from "./pages/Brand";
const { store } = configureStore();
function App() {
  return (
    <>
      <Provider store={store}>
        <ToastProvider autoDismiss>
          <Router>
            <NavBar />
            <Route exact path="/coupon">
              <Coupon />
            </Route>
            <Route exact path="/datesetting">
              <DateSetting />
            </Route>
            <Route exact path="/discount">
              <DiscountTransaction />
            </Route>
            <Route exact path="/employee">
              <EmployeePage />
            </Route>
            <Route exact path="/uploadEmployees">
              <UploadExcel />
            </Route>
            <Route
              path="/campaign"
              render={({ match: { url } }) => (
                <>
                  <PrivateRoute path={`${url}/`} exact>
                    <CampaignIndexPage />
                  </PrivateRoute>
                  <PrivateRoute path={`${url}/create`} exact>
                    <CampaignCreatePage />
                  </PrivateRoute>
                  <PrivateRoute path={`${url}/edit/:id`} exact>
                    <CampaignEditPage />
                  </PrivateRoute>
                </>
              )}
            ></Route>
            <Route exact path="/department">
              <DepartmentPage />
            </Route>
            <Route exact path="/brand">
              <BrandPage />
            </Route>
            <Route exact path="/campaign-use">
              <CampaignUsePage />
            </Route>
            <Route exact path="/campaign-coupon/:id">
              <CampaignCoupon />
            </Route>
            <Route exact path="/transaction">
              <Transaction />
            </Route>
            <Route exact path="/transaction-campaign/:id">
              <TransactionCampaign />
            </Route>
            <Route exact path="/chart">
              <ChartCount />
            </Route>
            <Route exact path="/">
              <IndexPage />
            </Route>
            <Switch>
              <PrivateRoute path="/register">
                <RegisterPage />
              </PrivateRoute>
              <Route path="/login">
                <LoginPage />
              </Route>
            </Switch>
          </Router>
        </ToastProvider>
      </Provider>
    </>
  );
}

export default App;
